import logo from "assets/logo.svg";
import { useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import SideMenu from "./Sidemenu/SideMenu";
import UserMenu from "./UserMenu";
import DateTime from "./DateTime/DateTime";
import Info from "./Info";
import classNames from "utilities/ClassNames";

export default function Header() {
  const [showSideMenu, setShowMenu] = useState(false);
  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          "lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}
      >
        <div className="relative z-50 flex items-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            {showSideMenu && <FiArrowLeft size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
          </div>
          <Link to="/">
            <img
              src={logo}
              className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex-1" />
        <DateTime />
        <Info />
        <UserMenu />
        <SideMenu
          closeMenu={() => setShowMenu(false)}
          showSideMenu={showSideMenu}
        />
      </div>
    </div>
  );
}
