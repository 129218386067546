import BrainImg from "components/shared/BrainImg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { navLinks } from "./config/navlinks";
import { appear } from "./config/appear";
import { slide } from "./config/slide";
import useApp from "hooks/useApp";

export default function SideMenu({ closeMenu, showSideMenu }: any) {
  const { updateDashboardWidgets, dashboardWidgets, userRole } = useApp();
  const { isScaled } = useScreen();
  const { pathname } = useLocation();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleNavLinkClick = (targetPath: any) => {
    if (window.location.pathname === "/3d-dashboard") {
      setShouldRefresh(true);
    }
    window.location.href = targetPath;
  };

  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  return (
    <motion.div
      initial="exit"
      animate={showSideMenu ? "enter" : "exit"}
      variants={appear}
      className="fixed z-40 inset-0 w-full bg-black/90"
      onClick={closeMenu}
    >
      <motion.div
        initial="exit"
        animate={showSideMenu ? "enter" : "exit"}
        variants={slide}
        className={classNames(
          "xl-2xl:w-[230px] 2xl:w-[260px]",
          "absolute h-full left-0 top-0 z-20 bg-gray"
        )}
      >
        <div className="h-full w-full mt-20 pb-20 flex flex-col justify-between">
          <ul className="flex flex-col">
            {navLinks(isScaled).map(
              (navLink, index) =>
                !(
                  (userRole !== "admin" && navLink.text === "Logs") ||
                  (pathname !== "/dashboard" &&
                    navLink.text === "Dashboard Configuration")
                ) && (
                  <NavLink
                    key={index}
                    to={navLink.to}
                    onClick={() => {
                      if (navLink.text === "Dashboard Configuration") {
                        closeMenu();
                        updateDashboardWidgets({
                          ...dashboardWidgets,
                          selectedType: "init",
                        });
                      } else {
                        handleNavLinkClick(navLink.to);
                      }
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                        : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
                    }
                  >
                    {navLink.icon}
                    <span className={"xl:text-xs xl-2xl:text-sm 2xl:text-base"}>
                      {navLink.text}
                    </span>
                  </NavLink>
                )
            )}
          </ul>
          <div className="grid place-content-center">
            <BrainImg />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
