import { AiOutlineFieldTime } from "react-icons/ai";
import { FiSettings, FiUsers } from "react-icons/fi";
import { RxDashboard, RxStopwatch, RxIdCard, RxGlobe } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";

export   const navLinks = (isScaled:any)=>{
  return(
   [
      {
        to: "/water-tower",
        icon: <TbBrandUnity size={isScaled ? 18 : 20} />,
        text: "Water Tower",
      },
      {
        to: "/users",
        icon: <FiUsers size={isScaled ? 18 : 20} />,
        text: "Users",
      },
      {
        to: "/logs",
        icon: <AiOutlineFieldTime size={isScaled ? 18 : 20} />,
        text: "Logs",
      },
      {
        to: `${process.env.REACT_APP_COMMON_APP}`,
        icon: <RxGlobe size={isScaled ? 18 : 20} />,
        text: "Back to Saudi Map",
      },
  ]

  );
};